/* You can add global styles to this file, and also import other style files */
@import '~ag-grid-community/styles//ag-grid.css';
@import '~ag-grid-community/styles//ag-theme-alpine.css';

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Regular.woff2')
    format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Medium.woff2')
    format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Bold.woff2')
    format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Thin.woff2')
    format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-BoldItalic.woff2')
    format('woff2');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-Italic.woff2')
    format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Neue Montreal';
  src: url('assets/fonts/neue-montreal/PPNeueMontreal-ThinItalic.ttf')
    format('ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Geist';
  src: url('assets//fonts/geist/Geist-VariableFont-wght.ttf');
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('assets//fonts/nunito-sans/NunitoSans-VariableFont.ttf');
}

body * {
  font-family: 'Neue Montreal';
}

:root {
  --blue-dark: #00197a;
  --brand-25: #f2faff;
  --brand-300: #74b9ff;
  --brand-600: #0044ff;
  --brand-700: #0044ff;
  --brand-800: #003de5;
  --gray-25: #fcfcfd;
  --gray-50: #f8fafc;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-800: #1e293b;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-900: #0f172a;
  --gray-950: #0c111d;
  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-700: #1d4ed8;
  --pink-50: #fdf2f8;
  --pink-200: #fbcfe8;
  --pink-700: #be185d;
  --purple-50: #faf5ff;
  --purple-200: #e9d5ff;
  --purple-700: #7e22ce;
  --sky-50: #f0f9ff;
  --sky-200: #bae6fd;
  --sky-500: #0ea5e9;
  --sky-700: #0369a1;
  --success-50: #f0fdf4;
  --success-200: #bbf7d0;
  --success-500: #22c55e;
  --success-700: #15803d;
  --error-50: #fef2f2;
  --error-200: #fecaca;
  --error-600: #dc2626;
  --error-700: #b91c1c;
  --zinc-50: #fafafa;
  --zinc-200: #e4e4e7;
  --zinc-700: #3f3f46;
  --warning-50: #fff7ed;
  --warning-200: #fed7aa;
  --warning-700: #c2410c;
  --amber-500: #f59e0b;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.ck-powered-by-balloon {
  display: none;
}
.clickable {
  cursor: pointer;
}

/*File upload styling*/
.file-input-wrapper {
  position: relative;
  width: 100%;
  padding: 0 0.75rem;
}

.file-input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.custom-file-input {
  width: 100%;
  height: 100px;
  border: 2px dashed #000209;
  background-color: #eff1f2;
  text-align: center;
  line-height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.custom-file-input label {
  font-size: 14px;
  color: #bfc0c2;
}

.upload-icon {
  opacity: 0.5;
  vertical-align: middle;
  margin-right: 5px;
}

.file-badge {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 16px;
  background-color: #eff1f2;
  align-self: start;
  margin-left: 0.5rem;
}
.file-badge .remove {
  cursor: pointer;
  margin-left: 1rem;
  background-color: rgb(128, 128, 128);
  padding: 0 0.4rem;
  border-radius: 100%;
  color: white;
}
.custom-select-panel {
  height: 100% !important;
  max-height: unset !important;
  display: flex !important;
  flex-wrap: wrap !important;
  height: 100% !important;
}
.custom-select-panel mat-option {
  flex-basis: 30% !important;
}
.mat-select-no-arrow .mat-mdc-select-arrow-wrapper {
  display: none;
}
.invalid,
.required {
  color: red;
}

a.view-navigation {
  line-height: 46px;
  margin-right: 8px;
  display: inline-block;
}
.btn_next::after {
  content: '\2192';
  margin-left: 10px;
  font-size: inherit;
}
.btn_add::before,
.btn_export::before,
.btn_edit::before,
.btn_reset::before,
.btn_pen::before {
  margin-right: 10px;
  font-size: inherit;
  color: inherit;
  display: flex;
  align-items: center;
  line-height: normal;
}
.btn_add,
.btn_export,
.btn_edit,
.btn_reset,
.btn_pen {
  display: flex;
  align-items: center;
}
.btn_add::before {
  content: url('/assets/images/plus.svg');
}
.btn_export::before {
  content: url('/assets/images/share.svg');
}
.btn_edit::before {
  content: url('/assets/images/edit.svg');
}
.btn_reset::before {
  content: url('/assets/images/refresh.svg');
}
.btn_pen::before {
  content: url('/assets/images/pencil-line.svg');
}
.lty-btn {
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Neue Montreal';
  font-weight: 500;
  line-height: 24px;
}
.lty-btn-primary {
  background-color: var(--brand-700);
  border: 1px solid var(--brand-700);
  color: white;
}
.lty-btn-primary:disabled {
  background-color: #6991ff;
  border: 1px solid #668fff;
  color: white;
}
.lty-btn-danger {
  background-color: var(--error-600);
  border: 1px solid var(--error-700);
  color: white;
}
.lty-btn-danger:disabled {
  background-color: #fe6f6f;
  border: 1px solid #fe6f6f;
  color: white;
}
.lty-btn-white {
  border: 1px solid var(--gray-300);
  background-color: white;
  color: var(--gray-800);
}
.lty-btn-white:disabled {
  background-color: rgb(242, 242, 242);
}
select.lty-form-control,
input.lty-form-control,
.lty-form .form-control {
  border: 1px solid #cbd5e1;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  background-color: white;
}
/*TODO: include in the rule above and remove important once redesign is done and stylesheet.css is deleted*/
.lty-form .ngx-mat-tel-input-container {
  border: 1px solid #cbd5e1 !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border-radius: 8px !important;
  background-color: white !important;
}
.lty-form mat-form-field.lty-input .mdc-text-field,
div.file {
  border: 1px solid #cbd5e1 !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border-radius: 8px !important;
  background-color: white !important;
  position: relative;
}
.lty-input .mat-mdc-select-disabled .mat-mdc-select-arrow-wrapper {
  display: none;
}
.file.alternate .upload-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid var(--gray-200);
  box-shadow: 0px 1px 2px 0px #1018280d;
  background-color: white;
}
.file.alternate {
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  min-height: 128px;
}
div.file.alternate.small {
  align-items: start;
  min-height: auto;
  height: 46px;
}
.lty-form label,
.file-label {
  margin-bottom: 16px;
  font-size: 16px;
  display: inline-block;
}
.uploaded-file-container {
  display: flex;
  column-gap: 16px;
}
.uploaded-file-container .uploaded-file-element {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--gray-200);
  border-radius: 12px;
  background-color: white;
  flex: 1;
  column-gap: 8px;
}
.uploaded-file-element .file-name {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  font-family: 'Neue Montreal';
  font-size: 14px;
}
.file-name > span:first-child {
  font-weight: 500;
  color: var(--gray-700);
}
.file-name > span:last-child {
  font-weight: 400;
  color: var(--gray-600);
}
.lty-form div.file.alternate .remove {
  color: red;
}
.lty-form div.file .file-name {
  font-weight: 500;
  font-family: 'Neue Montreal';
  color: var(--gray-950);
  font-size: 14px;
}
.file-name-container {
  display: flex;
  gap: 8px;
  position: absolute;
  right: 12px;
  font-weight: 500;
  font-family: 'Neue Montreal';
  color: var(--gray-950);
  font-size: 14px;
  width: 45%;
}
.file-name-container .remove {
  position: absolute;
  right: 0;
}
.file.small .file-name-container .file-name {
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  text-align: right;
  margin-right: 14px;
}
div.file.small{
  container-name: smallFile;
  container-type: inline-size;
}

@container smallFile (max-width: 256px) {
  div.file > span:first-of-type:not(:only-of-type){
    visibility: hidden;
  }
}

input.lty-form-control::-webkit-input-placeholder,
input.lty-form-control::-ms-input-placeholder,
input.lty-form-control::-moz-placeholder {
  font-family: 'Neue Montreal' !important;
}
.auth-container {
  padding: 32px 0;
}

@media screen and (min-width: 992px) {
  .auth-form {
    min-width: 475px;
    max-width: 40vw;
  }
  .auth-container {
    padding: 92px 0;
    min-height: 75vh;
  }
}
.lty-link {
  color: var(--brand-800);
  font-size: 14px;
  font-weight: 500;
  font-family: 'Neue Montreal';
}
.lty-link.link-disabled {
  color: #6991ff;
  cursor: not-allowed;
}
.lty-otp-input {
  border: 1px solid #cbd5e1 !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px !important;
  color: #64748b !important;
  font-family: 'Neue Montreal' !important;
  border-radius: 8px !important;
}
.layout-card {
  padding: 24px;
  border: 1px solid var(--gray-200);
  background-color: white;
  border-radius: 12px;
}
.layout-card.success {
  background-color: var(--success-500);
  color: white;
}
.layout-card.amber {
  background-color: var(--amber-500);
  color: white;
}
.layout-card.sky {
  background-color: var(--sky-500);
  color: white;
}
.list-header {
  display: flex;
  column-gap: 16px;
}
.list-header .search-bar {
  flex: 1;
}
.list-filters {
  display: flex;
  column-gap: 16px;
  width: max(35%, 20vw);
}

@media screen and (max-width: 991px) {
  .list-header,
  .list-filters {
    flex-wrap: wrap;
    row-gap: 16px;
  }
  .list-header .search-bar,
  .list-filters > *,
  .list-filters {
    width: 100%;
  }
}
.lty-input-icon-group {
  position: relative;
}
.lty-input-icon-group input {
  padding-left: 42px;
}
.lty-input-icon-group .input-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
input + .input-icon {
  left: 14px;
}
.lty-input-icon-group mat-select > div {
  padding-left: 32px !important;
}
.lty-form label,
.lty-form .form-group > label {
  color: var(--gray-700);
  font-family: 'Neue Montreal';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  line-height: 24px;
}

/* TODO: Delete the corresponding rule from stylesheet.css remove once the redesign is over*/
.lty-form .form-group {
  margin-bottom: 0;
}

.lty-table .ag-header-cell-label,
.ag-theme-alpine.lty-table .ag-header-row .ag-header-cell {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Neue Montreal';
  color: var(--gray-500);
}
.lty-table .ag-header-container {
  border-radius: 8px 8px 0 0;
  border: 1px solid var(--gray-50);
  border-bottom: none;
  background-color: var(--gray-50);
}
.lty-table.ag-theme-alpine .ag-row {
  border-bottom: 1px solid var(--gray-200);
  align-items: center;
  display: flex;
}
.lty-table .ag-row-even {
  background-color: white;
}
.lty-table .ag-row-odd {
  background-color: var(--gray-50);
}
.ag-theme-alpine.lty-table .ag-row .ag-cell {
  height: auto;
  padding: 0 18px;
}
.lty-table .ag-cell-inline-editing input,
.lty-table .ag-cell-inline-editing input:focus {
  border: none;
  background: transparent;
  box-shadow: none;
  outline: none;
}
.lty-table .ag-root-wrapper {
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
}
.lty-table .ag-header {
  border-bottom: 1px solid var(--gray-200);
}
.lty-table .simple-text-cell,
.lty-table .bold-text-cell {
  font-size: 14px;
  font-family: 'Neue Montreal';
  font-weight: 500;
}
.lty-table .simple-text-cell {
  color: #475467;
}
.lty-table .bold-text-cell {
  color: #101828;
}
.lty-table .editable-table-cell{
  min-height: 20px;
}
.lty-table .stacked-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0;
}
.lty-table .image-cell img {
  max-width: min(100%, 33px);
  max-height: min(100%, 33px);
}
.lty-table .stacked-text .main-text {
  font-family: 'Neue Montreal';
  font-size: 14px;
  color: #475467;
  font-weight: 500;
  line-height: 24px;
}
.lty-table .stacked-text .secondary-text {
  font-family: 'Neue Montreal';
  font-size: 14px;
  color: var(--gray-500);
  font-weight: 400;
  line-height: 24px;
}
.lty-table .checkbox-cell {
}
.lty-table .icon-cell span {
  display: flex;
  align-items: center;
}
.lty-table .icon-cell img {
  margin-right: 12px;
}

.info-container {
  display: flex;
  justify-content: space-between;
  font-family: 'Neue Montreal';
}
.info-block {
  display: flex;
  flex-direction: column;
  width: 45%;
  row-gap: 16px;
}
.info-separator {
  width: 1px;
  border-right: 1px solid var(--gray-200);
}
.info-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-element > span:first-child {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-500);
}
.info-element > span:last-child:not(:only-child) {
  font-size: 14px;
  color: var(--gray-950);
  font-weight: 500;
}
.info-element > div:last-child {
  width: 50%;
}
.info-element > span:last-child {
  max-width: 80%;
}

@media screen and (max-width: 991px) {
  .info-separator {
    display: none;
  }
  .info-container {
    flex-direction: column;
  }
  .info-block {
    width: 100%;
  }
}
.modal-submit-buttons {
  column-gap: 8px;
  display: flex;
  margin-left: auto;
}
.edge-element {
  position: absolute;
  right: 15px;
  top: 14px;
  line-height: 1;
  font-size: 17px;
  color: #6b7280;
  z-index: 2;
}
.lty-form .modal-body .form-group {
  margin-bottom: 16px;
}
.lty-dropdown li:hover,
.lty-dropdown a:hover {
  background-color: var(--brand-800);
  color: white;
}
.split-form-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.split-form-body > * {
  width: 100%;
}
.form-subtitle {
  font-family: 'Neue Montreal';
  font-weight: 500;
  font-size: 16px;
  color: var(--gray-700);
}
.highlighted-text {
  color: var(--brand-700) !important;
  font-weight: 500;
}
.pac-container {
  z-index: 100000;
}
.lty-badge {
  font-family: 'Neue Montreal';
  padding: 2px 8px;
  border: 1px solid var(--gray-100);
  font-weight: 500;
  font-size: 12px;
  border-radius: 16px;
  background-color: var(--gray-50);
}
.lty-badge.danger,
.select-outline.danger {
  color: var(--error-700);
  background-color: var(--error-50);
  border-color: var(--error-200);
}
.lty-badge.success,
.select-outline.success {
  color: var(--success-700);
  border-color: var(--success-200);
  background-color: var(--success-50);
}
.lty-badge.primary,
.select-outline.primary {
  color: var(--blue-700);
  border-color: var(--blue-200);
  background-color: var(--blue-50);
}
.lty-badge.sky,
.select-outline.sky {
  color: var(--sky-700);
  border-color: var(--sky-200);
  background-color: var(--sky-50);
}
.lty-badge.purple,
.select-outline.purple {
  color: var(--purple-700);
  border-color: var(--purple-200);
  background-color: var(--purple-50);
}
.lty-badge.warning,
.select-outline.warning {
  color: var(--warning-700);
  border-color: var(--warning-200);
  background-color: var(--warning-50);
}
.lty-badge.zinc,
.select-outline.zinc {
  color: var(--zinc-700);
  border-color: var(--zinc-200);
  background-color: var(--zinc-50);
}
.lty-badge.pink,
.select-outline.pink {
  color: var(--pink-700);
  border-color: var(--pink-200);
  background-color: var(--pink-50);
}
.lty-badge.blue,
.select-outline.blue {
  color: var(--blue-700);
  border-color: var(--blue-200);
  background-color: var(--blue-50);
}
.view-subtitle {
  font-family: 'Neue Montreal';
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-700);
  display: flex;
  align-items: center;
}
.lty-chip {
  border: 1px solid var(--gray-300) !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 3px 5px !important;
  height: fit-content !important;
}
.lty-input .lty-chip {
  margin-right: 6px;
}
.lty-select-panel {
  border: 1px solid var(--gray-400);
  box-shadow: none;
}
.lty-select-panel .mat-pseudo-checkbox {
  border-color: var(--gray-400);
  border-radius: 4px;
}
.lty-select-panel .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background-color: var(--brand-600);
}
.lty-chip
  .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action
  .mdc-evolution-chip__action--trailing {
  padding: 0 !important;
}
.lty-chip .mdc-evolution-chip__text-label {
  color: var(--gray-700);
  font-size: 12px;
  font-weight: 500;
  font-family: 'Neue Montreal';
}

.lty-form input:focus,
.lty-form select:focus,
.lty-form mat-form-field:focus-within {
  box-shadow:
    0px 0px 0px 4px #0f4af53d,
    0px 1px 2px 0px #1018280d;
  border: 1px solid var(--brand-300);
}
.lty-form mat-form-field input {
  border: none !important;
  box-shadow: none !important;
}
.lty-form mat-form-field:focus-within,
.lty-form mat-form-field:focus-within > div.mdc-text-field {
  border-radius: 8px !important;
  border: 1px solid var(--brand-300);
}
.lty-table .actionDot {
  display: flex;
  justify-content: center;
}

.lty-table .actionDot .dropdown.dot-mnu {
  position: unset;
  display: flex;
  justify-content: center;
  margin-left: auto;
}
.danger {
  color: var(--error-700);
}
textarea.textarea-lg {
  min-height: 180px !important;
}
textarea[readonly] {
  cursor: not-allowed;
}
.mat-mdc-menu-content i {
  margin-right: 8px;
}
.secondary-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-500);
  line-height: 24px;
}
.strong-text {
  font-size: 14px;
  color: var(--gray-950);
  font-weight: 500;
  line-height: 24px;
}

.select-outline {
  background-color: transparent;
  border-radius: 16px;
  padding: 2px 10px 2px 8px;
  border-width: 1px;
  border-style: solid;
  font-weight: 500;
}
.select-outline-sm {
  font-size: 14px;
}
.tox-statusbar {
  display: none !important;
}
.lty-form .ng2-tag-input {
  padding: 0 !important;
  border: 1px solid #cbd5e1 !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
}
.lty-form .ng2-tag-input input {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.lty-form .ng2-tag-input tag {
  margin-top: 8px;
}
.checkboxes-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.checkboxes-container > .form-check {
  width: 30%;
}
